<div class="container-fluid clp-container">
  <div class="row">
    <div class="container d-flex d-flex h-100 mx-auto clp-container-individual">
      <div class="row justify-content-center align-self-center">
        <div class="col-md-6 clp-bg-blue p-5 d-flex">
          <div class="row justify-content-center align-self-center">
            <div>
              <h1 class="clp-title">{{ html.title }}</h1>
              <img src="{{ html.image }}" class="img-fluid d-none d-md-block" />
            </div>
          </div>
        </div>
        <div class="col-md-6 clp-bg-light-blue p-5 d-md-flex">
          <div class="row justify-content-center align-self-center">
            <div>
              <h2 class="clp-sub-title">{{ html.lead }}</h2>
              <p *ngFor="let sublead of html.sublead">
                {{ sublead.text }}
              </p>
              <p>{{ html.list.title }}</p>
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item d-flex align-items-center bg-transparent border-0 ml-0 pl-0 py-1"
                  *ngFor="let item of html.list.items"
                >
                  <img src="{{ item.icon }}" class="img-fluid" />
                  <span class="ml-3">{{ item.text }}</span>
                </li>
              </ul>
              <button
                type="button"
                class="btn btn-primary"
                (click)="nextFeature()"
              >
                {{ html.cta.text }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
