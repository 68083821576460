import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';

import { ClpFormModule } from '../shared/form/form.module';
import { ClpTopNavigationModule } from '../shared/top-navigation/top-navigation.module';
import { ClpGettingAroundComponent } from './clp-getting-around.component';

@NgModule({
  declarations: [
    ClpGettingAroundComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FeatureScrollerModule,
    ClpFormModule,
    ClpTopNavigationModule
  ],
  exports: [
    ClpGettingAroundComponent
  ]
})
export class ClpGettingAroundModule { }