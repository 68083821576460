
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureComponentRegistryService } from './feature-component-registry/feature-component-registry.service';

import { ClpSummaryComponent } from './clp-summary/clp-summary.component';
import { ClpSummaryModule } from './clp-summary/clp-summary.module';

import { ClpIndividualComponent } from './clp-individual/clp-individual.component';
import { ClpIndividualModule } from './clp-individual/clp-individual.module';

import { ClpHomeEnergyComponent } from './clp-home-energy/clp-home-energy.component';
import { ClpHomeEnergyModule } from './clp-home-energy/clp-home-energy.module';

import { ClpHomeEnergy2Component } from './clp-home-energy-2/clp-home-energy.component';
import { ClpHomeEnergy2Module } from './clp-home-energy-2/clp-home-energy.module';

import { ClpFoodWasteComponent } from './clp-food-waste/clp-food-waste.component';
import { ClpFoodWasteModule } from './clp-food-waste/clp-food-waste.module';

import { ClpGettingAroundComponent } from './clp-getting-around/clp-getting-around.component';
import { ClpGettingAroundModule } from './clp-getting-around/clp-getting-around.module';

import { ClpGettingAround2Component } from './clp-getting-around-2/clp-getting-around.component';
import { ClpGettingAround2Module } from './clp-getting-around-2/clp-getting-around.module';

import { ClpTravelShoppingComponent } from './clp-travel-shopping/clp-travel-shopping.component';
import { ClpTravelShoppingModule } from './clp-travel-shopping/clp-travel-shopping.module';

import { ClpTravelShopping2Component } from './clp-travel-shopping-2/clp-travel-shopping.component';
import { ClpTravelShopping2Module } from './clp-travel-shopping-2/clp-travel-shopping.module';

import { TabCarouselModule } from './tab-carousel/tab-carousel.module';
import { TabContentHostModule } from './tab-carousel/tab-content-host.module';

import { ClpFormModule } from './shared/form/form.module';
import { ClpFormComponent } from './shared/form/form.component';

import { ClpTopNavigationModule } from './shared/top-navigation/top-navigation.module';
import { ClpTopNavigationComponent } from './shared/top-navigation/top-navigation.component';

@NgModule({
    imports: [
      CommonModule,
      ClpSummaryModule,
      ClpHomeEnergyModule,
      ClpHomeEnergy2Module,
      ClpFoodWasteModule,
      ClpGettingAroundModule,
      ClpGettingAround2Module,
      ClpTravelShoppingModule,
      ClpTravelShopping2Module,
      ClpIndividualModule,
      TabCarouselModule,
      TabContentHostModule,
      ClpFormModule,
      ClpTopNavigationModule
    ],
    providers: [
      FeatureComponentRegistryService,
    ],
    entryComponents: [
      ClpHomeEnergyComponent,
      ClpHomeEnergy2Component,
      ClpFoodWasteComponent,
      ClpGettingAroundComponent,
      ClpGettingAround2Component,
      ClpTravelShoppingComponent,
      ClpTravelShopping2Component,
      ClpSummaryComponent,
      ClpIndividualComponent,
      ClpFormComponent,
      ClpTopNavigationComponent
    ],
    declarations: []
})
export class ClpCalculatorModule {}
