import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clp-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})

export class ClpTopNavigationComponent implements OnInit {

  @Input() 
  cleanFeatureComponents : any;
  
  @Input() 
  activeComponentGroup : any

  @Output()
  start: EventEmitter<null>;

  constructor() {
    this.start = new EventEmitter<null>();
  }

  ngOnInit(): void {}
  
  goStart() {
    this.start.emit();
  }
}
