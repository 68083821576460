import { Component, Injector, OnInit } from '@angular/core';
import { FeatureComponent } from '../../calculators/feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

import { AppConfiguration } from '../../../bootstrap/clp/app.configuration';

import Chart from 'chart.js/auto';

@Component({
  selector: 'app-clp-summary',
  templateUrl: './clp-summary.component.html',
  styleUrls: ['../clp-calculator.component.css'],
})
export class ClpSummaryComponent implements OnInit, FeatureComponent {
  data: any;

  donutChart: any;

  page: string = 'summary';

  rand: number;

  appConfiguration: AppConfiguration;

  imageUrl: any;

  html: any = {};

  featureComponents: any = {};

  cleanFeatureComponents: any = [];

  activeComponentGroup: any = [];

  constructor(private injector: Injector) {
    this.appConfiguration = this.injector.get(AppConfiguration);
  }

  ngOnInit(): void {
    this.featureComponents =
      this.appConfiguration.getEnvironment().featureComponents;

    this.featureComponents =
      this.appConfiguration.getEnvironment().featureComponents;

    const component = this.featureComponents.find((x) => x.name == this.page);
    this.html = component.html;

    console.log(this.html);

    this.featureComponents.forEach((featureComponent) => {
      if (featureComponent.main) {
        const numbersSumTo = this.numbersSumTo(4, this.generateRandom(70, 100));
        featureComponent.charts.color.forEach((item, index) => {
          featureComponent.charts.color[index].percentage = numbersSumTo[index];
        });
        featureComponent.charts.title = featureComponent.title;
        featureComponent.charts.tonne = this.getRandomFloat(0.1, 9.9, 2);
        this.cleanFeatureComponents.push(featureComponent);
      }
    });

    this.createChart();

    this.rand = this.getRandomFloat(0.1, 99.1, 2);
  }

  nextFeature() {
    this.injector.get(NavigationService).slideActiveTabForward();
  }

  previousFeature() {
    this.injector.get(NavigationService).slideActiveTabBack();
  }

  createChart() {
    this.donutChart = new Chart('donurtChart', {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.cleanFeatureComponents.map((component) => {
              return component.charts.tonne;
            }),
            backgroundColor: this.cleanFeatureComponents.map((component) => {
              return component.charts.color[0].code;
            }),
            hoverOffset: 4,
          },
        ],
      },
      options: {
        aspectRatio: 1.5,
        responsive: true,
      },
    });
  }
  isEmpty(value: any) {
    return value == '' || value == null;
  }

  getRandomFloat(min: number, max: number, decimals: number) {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
  }

  numbersSumTo(length: number, value: number) {
    const fourRandomNumbers = Array.from(
      { length: length },
      () => Math.floor(Math.random() * 6) + 1
    );
    const res = fourRandomNumbers
      .map(
        (num) => (num / fourRandomNumbers.reduce((a, b) => a + b, 0)) * value
      )
      .map((num) => Math.trunc(num));
    res[0] += Math.abs(res.reduce((a, b) => a + b, 0) - value);
    return res;
  }

  generateRandom(min = 0, max = 100) {
    // find diff
    let difference = max - min;

    // generate random number
    let rand = Math.random();

    // multiply with difference
    rand = Math.floor(rand * difference);

    // add with min value
    rand = rand + min;

    return rand;
  }
}
