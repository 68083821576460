<section class="clp-summary-container mb-4">
  <div class="container" style="max-width: 1320px">
    <div class="row">
      <div class="col-md-12 bg-white p-5 rounded">
        <div class="row">
          <div class="col-md-4 space-vertical pb-5 pb-md-0">
            <h2 class="title">{{ html.sections[0].title }}</h2>
            <div>
              <span class="tonnes-eq"> {{ rand }}</span>
              <p>tonnes C02 Equivalent</p>
            </div>
            <button class="btn btn-primary btn-lg d-none d-md-block">
              {{ html.sections[0].cta }}
            </button>
          </div>
          <div class="col-md-4 pb-5 pb-md-0">
            <div class="chart-container">
              <canvas id="donurtChart">{{ donutChart }}</canvas>
            </div>
          </div>
          <div class="col-md-4 space-vertical">
            <div
              *ngFor="let component of cleanFeatureComponents; let j = index"
              class="mb-3 color-swatch"
            >
              <label
                >{{ component.charts.title }}:
                {{ component.charts.tonne }} tonnes</label
              >
              <div
                class="progress bg-white"
                style="max-width: 100%; height: 47px"
              >
                <div
                  class="progress-bar progress-bar-stripped"
                  [ngStyle]="{
                    'background-color': color.code,
                    width: color.percentage + '%'
                  }"
                  *ngFor="let color of component.charts.color; let j = index"
                ></div>
              </div>
            </div>

            <button class="btn btn-primary btn-lg d-block d-md-none mt-4">
              Download Full Report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="clp-summary-container mb-4 section-2">
  <div class="container" style="max-width: 1320px">
    <div class="row justify-content-between">
      <div
        class="col-md-6 bg-white justify-content-between p-5 rounded space-vertical"
      >
        <h5 class="sub-title mb-5">{{ html.sections[1].left.title }}</h5>
        <div class="row">
          <div
            class="col-sm-6 col-md-6 text-center mb-1"
            *ngFor="let item of html.sections[1].left.lists"
          >
            <img src="{{ item.icon }}" class="d-block mx-auto" />
            <p>{{ item.label }}</p>
            <span class="empha"
              ><span class="mr-2"> {{ item.tonne }}</span
              >tonnes</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-md-6 bg-white justify-content-between p-5 rounded space-vertical"
      >
        <h5 class="sub-title mb-5">{{ html.sections[1].right.title }}</h5>

        <label class="empha"
          >{{ html.sections[1].right.desc
          }}<span class="ml-2 mr-2">&lt;{{ rand }}</span
          >tonnes</label
        >
        <p>
          {{ html.sections[1].right.longDesc }}
        </p>
        <div>
          <button class="btn btn-primary btn-lg float-right">
            {{ html.sections[1].right.cta }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="clp-summary-container mb-4">
  <div class="container" style="max-width: 1320px">
    <div class="row">
      <div class="col-md-12 bg-white p-5 rounded">
        <h5 class="sub-title mb-5">{{ html.sections[2].title }}</h5>
        <p *ngFor="let desc of html.sections[2].descs">
          {{ desc.text }}
        </p>
        <div>
          <button class="btn btn-primary btn-lg float-right">
            {{ html.sections[2].cta }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="clp-summary-container mb-4">
  <div class="container" style="max-width: 1320px">
    <div class="row">
      <div
        class="col-md-12 bg-white p-5 rounded" style="background-position: bottom
        right; background-repeat: no-repeat;"
        [ngStyle]="{
          'background-image': 'url(' + html.sections[3].image + ')'
        }"
      >
        <h5 class="sub-title mb-5">{{ html.sections[3].title }} </h5>
        <p *ngFor="let desc of html.sections[2].descs">
          {{ desc.text }}
        </p>
        <div>
          <button class="btn btn-primary btn-lg float-right"> {{ html.sections[3].cta }}</button>
        </div>
      </div>
    </div>
  </div>
</section>
