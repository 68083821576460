import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';

import { ClpFormModule } from '../shared/form/form.module';
import { ClpTopNavigationModule } from '../shared/top-navigation/top-navigation.module';
import { ClpHomeEnergyComponent } from './clp-home-energy.component';

@NgModule({
  declarations: [
    ClpHomeEnergyComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FeatureScrollerModule,
    ClpFormModule,
    ClpTopNavigationModule
  ],
  exports: [
    ClpHomeEnergyComponent
  ]
})
export class ClpHomeEnergyModule { }