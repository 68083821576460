import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
//import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';
import { ClpIndividualComponent } from './clp-individual.component';

@NgModule({
  declarations: [
    ClpIndividualComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    //FeatureScrollerModule
  ],
  exports: [
    ClpIndividualComponent
  ]
})
export class ClpIndividualModule { }