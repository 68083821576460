import { Injectable, Injector } from '@angular/core';

import { ClpIndividualComponent } from '../clp-individual/clp-individual.component';
import { ClpHomeEnergyComponent } from '../../../features/clp/clp-home-energy/clp-home-energy.component';
import { ClpHomeEnergy2Component } from '../../../features/clp/clp-home-energy-2/clp-home-energy.component';
import { ClpFoodWasteComponent } from '../../../features/clp/clp-food-waste/clp-food-waste.component';
import { ClpGettingAroundComponent } from '../../../features/clp/clp-getting-around/clp-getting-around.component';
import { ClpGettingAround2Component } from '../../../features/clp/clp-getting-around-2/clp-getting-around.component';
import { ClpTravelShoppingComponent } from '../../../features/clp/clp-travel-shopping/clp-travel-shopping.component';
import { ClpTravelShopping2Component } from '../../../features/clp/clp-travel-shopping-2/clp-travel-shopping.component';
import { ClpSummaryComponent } from '../../../features/clp/clp-summary/clp-summary.component';
import { FeatureComponent } from './feature-component.interface';

type ComponentClass = { new (injector: Injector): FeatureComponent };

@Injectable()
export class FeatureComponentRegistryService {

  private componentMap: Map<string, ComponentClass>;

  constructor() {
    this.buildComponentMap();
  }

  getComponent(componentName: string): ComponentClass {
    if (!this.componentMap.has(componentName)) {
      return null;
    }
    return this.componentMap.get(componentName);
  }

  private buildComponentMap() {
    this.componentMap = new Map<string, ComponentClass>();
    this.componentMap.set('ClpIndividualComponent', ClpIndividualComponent);
    this.componentMap.set('ClpHomeEnergyComponent', ClpHomeEnergyComponent);
    this.componentMap.set('ClpHomeEnergy2Component', ClpHomeEnergy2Component);
    this.componentMap.set('ClpFoodWasteComponent', ClpFoodWasteComponent);
    this.componentMap.set('ClpGettingAroundComponent', ClpGettingAroundComponent);
    this.componentMap.set('ClpGettingAround2Component', ClpGettingAround2Component);
    this.componentMap.set('ClpTravelShoppingComponent', ClpTravelShoppingComponent);
    this.componentMap.set('ClpTravelShopping2Component', ClpTravelShopping2Component);
    this.componentMap.set('ClpSummaryComponent', ClpSummaryComponent);
  }
}
