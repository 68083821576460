import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FeatureScrollerModule } from '../../../shared/components/feature-scroller/feature-scroller.module';
import { ClpSummaryComponent } from './clp-summary.component';

@NgModule({
  declarations: [
    ClpSummaryComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FeatureScrollerModule
  ],
  exports: [
    ClpSummaryComponent
  ]
})
export class ClpSummaryModule { }