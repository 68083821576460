import { Component, Injector, OnInit } from '@angular/core';
import { FeatureComponent } from '../../calculators/feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { AppConfiguration } from '../../../bootstrap/clp/app.configuration';

@Component({
  selector: 'app-clp-travel-shopping',
  templateUrl: './clp-travel-shopping.component.html',
  styleUrls: ['../clp-calculator.component.css']
})

export class ClpTravelShoppingComponent implements OnInit, FeatureComponent {

  page:string = "travel-shopping";

  data: any;

  appConfiguration: AppConfiguration;

  imageUrl : any;
   
  html: any = {};

  featureComponents: any = {};

  cleanFeatureComponents : any = [];

  activeComponentGroup : any;

  constructor(private injector: Injector) { 
    this.appConfiguration = this.injector.get(AppConfiguration);
  }

  ngOnInit(): void {
    this.featureComponents = this.appConfiguration.getEnvironment().featureComponents;

    const component = this.featureComponents.find(x => x.name == this.page);
    this.html = component.html;

    this.featureComponents.forEach(featureComponent => {
      if(!this.isEmpty(featureComponent.group) && this.cleanFeatureComponents.indexOf(featureComponent.title) === -1){
        this.cleanFeatureComponents.push(featureComponent.title);
      }
    });
    this.activeComponentGroup = component.title;
  }

  nextFeature() {
    this.injector.get(NavigationService).slideActiveTabForward();
  }

  previousFeature() {
    this.injector.get(NavigationService).slideActiveTabBack();
  }

  start(){
    this.injector.get(NavigationService).slideActiveTabStart();
  }

  isEmpty(value : any){
    return (value == "" || value == null);
  };
}
