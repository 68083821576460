import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppConfiguration } from './app.configuration';

const component = {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
}

@Component(component)
export class AppComponent implements OnInit {
  constructor(
    private appConfiguration: AppConfiguration,
    private translate: TranslateService) {
    const environment = this.appConfiguration.getEnvironment()

    this.translate.setDefaultLang(environment.defaultLanguage);
    this.translate.use(environment.defaultLanguage);
  }

  ngOnInit() {}
}
