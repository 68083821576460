<div class="form">
    <h2 class="d-block d-md-none mb-3" *ngIf="!isEmpty(html.form.title)">{{ html.form.title }}</h2>
    <p *ngIf="!isEmpty(html.form.sublead )" class="text-right"> 
        {{ html.form.sublead }} 
    </p>
    <ng-container *ngFor="let field of html.form.fields; let i = index;">
        <div class="form-group mb-4" *ngIf="field.type == 'text' || field.type == 'email' || field.type == 'password'">
            <label [for]="field.name +  i">{{ field.label }}</label>
            <input [type]="field.type" class="form-control" [id]="field.name + i" [name]="field.name" />
        </div>
        <div class="form-group mb-4" *ngIf="field.type == 'radio'">
            <label [for]="field.name +  i">{{ field.label }}</label>
            <div class="custom-control custom-radio" *ngFor="let option of field.options; let j = index;">
                <input [type]="field.type" [id]="field.name + j" [name]="field.name" class="custom-control-input">
                <label class="custom-control-label" [for]="field.name +  i"> {{ option.text }}</label>
            </div>
        </div>
        <div *ngIf="field.type == 'label'" class="form-group mb-1">
            <label>{{ field.label }}</label>
        </div>
        <div *ngIf="field.type == 'group'" class="form-group mb-1">
            <label *ngIf="!isEmpty(field.label)">{{ field.label }}</label>
            <div class="form-group">
                <label *ngIf="!isEmpty(field.fields.label)">{{ field.fields.label }}</label>
                <div class="form-group d-flex">
                    <ng-container *ngFor="let field of field.fields.fields; let j = index;">
                        <input [type]="field.type" class="form-control mr-2" [id]="field.name + i" [name]="field.name" *ngIf="field.type == 'text' || field.type == 'email' || field.type == 'password'"/>
                        <select [id]="field.name + j" class="form-control" *ngIf="field.type == 'select'">
                            <option *ngFor="let option of field.options; let j = index;"> {{ option.text }} </option>
                        </select>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="col d-flex px-0 form-buttons mt-4">
        <button type="button" class="btn btn-primary mr-3" (click)="goBack()"  *ngIf="showBack">{{ html.form.cta.prev.text }}</button>
        <button type="button" class="btn btn-primary" (click)="goForward()" *ngIf="enableNext">{{  html.form.cta.next.text }}</button>
    </div>
</div>