<div class="d-flex align-items-top">
  <div class="d-flex mt-2" (click)="goStart()" style="cursor: pointer;">
    <div><img src="/assets/img/clp/icons/Back.png" style="width: 40px;"></div>
    <div><img src="/assets/img/clp/icons/Home.png" style="width: 40px;"/></div>
  </div>
  <ul class="step d-flex flex-nowrap">
    <li class="step-item " [ngClass]="{ 'active': component  == activeComponentGroup }" *ngFor="let component of cleanFeatureComponents; let j = index;">
      <a class="">{{ component }}</a>
    </li>
    <!--
       <li class="step-item active">
          <a class="">Step 3</a>
       </li>
    -->
  </ul> 
</div>
