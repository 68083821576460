// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  defaultLanguage: 'en',
  //  componentHierarchy: {
  //    headerComponents: [],
  featureComponents: [
    {
      name: 'individual',
      title: 'Individual',
      component: {
        templateName: 'clpIndividual',
        componentName: 'ClpIndividualComponent',
      },
      html: {
        title: 'Individual',
        image: '/assets/img/clp/heroes/IndividualCalculator.png',
        lead: 'Take a few minutes to learn about how your personal choice influence your carbon footprint.',
        sublead: [{ text: 'The data you provide will be kept anonymous.' }],
        list: {
          title: 'The calculator will take 4 parts:',
          items: [
            {
              icon: '/assets/img/clp/icons/HomeEnergy.png',
              text: 'Home Energy',
            },
            {
              icon: '/assets/img/clp/icons/FoodWaste.png',
              text: 'Food & Waste',
            },
            {
              icon: '/assets/img/clp/icons/GettingAround.png',
              text: 'Getting Around',
            },
            {
              icon: '/assets/img/clp/icons/TravelShopping.png',
              text: 'Travel & Shopping',
            },
          ],
        },
        cta: { text: 'Start Now' },
      },
    },
    {
      name: 'home-energy',
      title: 'Home Energy',
      group: '1',
      main: '1',
      component: {
        templateName: 'clpHomeEnergy',
        componentName: 'ClpHomeEnergyComponent',
      },
      html: {
        title: 'Home Energy',
        seqn: '1',
        image: 'assets/img/clp/heroes/HomeEnergy.png',
        form: {
          title: 'Home Energy',
          sublead: 'Step 1/2',
          fields: [
            {
              type: 'text',
              name: 'num_household',
              label: 'How many people live in your household?',
              tooltip: 'What is this?',
            },
            {
              type: 'radio',
              name: 'sort_home',
              label: 'What sort of home do you live in?',
              options: [
                { text: 'Single Family, seperated' },
                { text: 'Single Family, connected' },
                { text: 'Multi family, low-rise' },
                { text: 'Multi family, high-rise' },
              ],
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'What is the size of your home?',
                fields: [
                  { type: 'text', name: 'size_home', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
      charts: {
        color: [
          { code: '#3752E6', percentage: 0 },
          { code: '#5D73E9', percentage: 0 },
          { code: '#8393EC', percentage: 0 },
          { code: '#A9B4EF', percentage: 0 },
        ],
        tonne: '0',
      },
    },
    {
      name: 'home-energy-2',
      title: 'Home Energy',
      group: '1',
      component: {
        templateName: 'clpHomeEnergy',
        componentName: 'ClpHomeEnergy2Component',
      },
      html: {
        title: 'Home Energy',
        seqn: '1',
        image: 'assets/img/clp/heroes/HomeEnergy.png',
        form: {
          title: 'Home Energy',
          sublead: 'Step 2/2',
          fields: [
            {
              type: 'group',
              label: '',
              fields: {
                label:
                  'Do you know how much electricity your household used during the year? Enter as kwh or $',
                fields: [
                  {
                    type: 'text',
                    name: 'electricity_used',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'label',
              label:
                'Do you use any other fuels for heat, hot water of cooking?',
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Natural Gas',
                fields: [
                  {
                    type: 'text',
                    name: 'natural_gas',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Propane',
                fields: [
                  { type: 'text', name: 'propane', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Oil',
                fields: [
                  { type: 'text', name: 'oil', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
    },
    {
      name: 'food-waste',
      title: 'Food & Waste',
      group: '2',
      main: '1',
      component: {
        templateName: 'clpFoodWaste',
        componentName: 'ClpFoodWasteComponent',
      },
      html: {
        title: 'Food & Waste',
        seqn: '2',
        image: 'assets/img/clp/heroes/FoodWaste.png',
        form: {
          title: 'Food & Waste',
          sublead: 'Step 1/1',
          fields: [
            {
              type: 'radio',
              name: 'diet',
              label: 'What best describes your diet?',
              options: [
                { text: 'Vegan' },
                { text: 'Vegetarian, dairy and eggs' },
                { text: 'Fish or white meat only or red meat rarely' },
                { text: 'Red meat (beef, lamb etc) frequently' },
              ],
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Do you have Pets',
                fields: [
                  { type: 'text', name: 'pets', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'label',
              label: 'How much waste do you dispose in a typical week?',
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Waste',
                fields: [
                  { type: 'text', name: 'waste', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Recycling',
                fields: [
                  { type: 'text', name: 'recycling', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Compost',
                fields: [
                  { type: 'text', name: 'compost', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
      charts: {
        color: [
          { code: '#9BD3DD', percentage: 0 },
          { code: '#AFDCE4', percentage: 0 },
          { code: '#C3E5EB', percentage: 0 },
          { code: '#D7EDF1', percentage: 0 },
        ],
        tonne: '0',
      },
    },
    {
      name: 'getting-around',
      title: 'Getting Around',
      group: '3',
      main: '1',
      component: {
        templateName: 'clpGettingAround',
        componentName: 'ClpGettingAroundComponent',
      },
      html: {
        title: 'Getting Around',
        seqn: '3',
        image: 'assets/img/clp/heroes/GettingAround.png',
        form: {
          title: 'Getting Around',
          sublead: 'Step 1/2',
          fields: [
            {
              type: 'radio',
              name: 'vehicle',
              label: 'Do you own a vehicle?',
              options: [{ text: 'Yes' }, { text: 'No' }],
            },
            {
              type: 'radio',
              name: 'vehicle_power',
              label: 'If yes, how is it powered?',
              options: [
                { text: 'Conventional' },
                { text: 'Electric Vehicle' },
                { text: 'Hybrid' },
              ],
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Do you know how efficient your vehicle is?',
                fields: [
                  {
                    type: 'text',
                    name: 'vehicle_efficiency',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'What is your annual mileage for your vehicle (km)?',
                fields: [
                  {
                    type: 'text',
                    name: 'annual_milage',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
      charts: {
        color: [
          { code: '#B9D9EB', percentage: 0 },
          { code: '#C7E1EF', percentage: 0 },
          { code: '#D5E8F3', percentage: 0 },
          { code: '#E3F0F7', percentage: 0 },
        ],
        tonne: '0',
      },
    },
    {
      name: 'getting-around-2',
      title: 'Getting Around',
      group: '3',
      component: {
        templateName: 'clpGettingAround',
        componentName: 'ClpGettingAround2Component',
      },
      html: {
        title: 'Getting Around',
        seqn: '3',
        image: 'assets/img/clp/heroes/GettingAround.png',
        form: {
          title: 'Getting Around',
          sublead: 'Step 2/2',
          fields: [
            {
              type: 'label',
              label: 'How much do you use non-auto forms of transportation?',
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Bike or Walking?',
                fields: [
                  {
                    type: 'text',
                    name: 'bike_walking',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Bus',
                fields: [
                  { type: 'text', name: 'bus', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Taxi or Ride Share',
                fields: [
                  {
                    type: 'text',
                    name: 'taxi_ride_share',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Subway / MTR',
                fields: [
                  {
                    type: 'text',
                    name: 'subway_mtr',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Train',
                fields: [
                  { type: 'text', name: 'train', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Ferry',
                fields: [
                  { type: 'text', name: 'Ferry', tooltip: 'What is this?' },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
    },
    {
      name: 'travel-shopping',
      title: 'Travel & Shopping',
      group: '4',
      main: '1',
      component: {
        templateName: 'clpTravelShopping',
        componentName: 'ClpTravelShoppingComponent',
      },
      html: {
        title: 'Travel & Shopping',
        seqn: '4',
        image: 'assets/img/clp/heroes/TravelShopping.png',
        form: {
          title: 'Travel & Shopping',
          sublead: 'Step 1/2',
          fields: [
            {
              type: 'label',
              label: 'How many flights did you take in the last year?',
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Short Flights (under 2 hrs)',
                fields: [
                  {
                    type: 'text',
                    name: 'short_flights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Medium Flights',
                fields: [
                  {
                    type: 'text',
                    name: 'medium_flights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Long Flights',
                fields: [
                  {
                    type: 'text',
                    name: 'long_flights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'radio',
              name: 'vehicle_power',
              label: 'What class do you typically fly?',
              options: [
                { text: 'Coach' },
                { text: 'Business' },
                { text: 'First' },
              ],
            },
            {
              type: 'label',
              label: 'Do you use any lodging this year?',
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Standard Hotel Nights',
                fields: [
                  {
                    type: 'text',
                    name: 'standard_hotel_nights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Luxury Hotel Nights',
                fields: [
                  {
                    type: 'text',
                    name: 'luxury_hotel_nights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
            {
              type: 'group',
              label: '',
              fields: {
                label: 'Cruise Ship Nights',
                fields: [
                  {
                    type: 'text',
                    name: 'cruise_ship_nights',
                    tooltip: 'What is this?',
                  },
                  { type: 'select', options: [{ text: 'Unit' }] },
                ],
              },
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
      charts: {
        color: [
          { code: '#008AD8', percentage: 0 },
          { code: '#33A1E0', percentage: 0 },
          { code: '#66B9E8', percentage: 0 },
          { code: '#B9D9EB', percentage: 0 },
        ],
        tonne: '0',
      },
    },
    {
      name: 'travel-shopping-2',
      title: 'Travel & Shopping',
      group: '4',
      component: {
        templateName: 'clpTravelShopping',
        componentName: 'ClpTravelShopping2Component',
      },
      html: {
        title: 'Travel & Shopping',
        seqn: '4',
        image: 'assets/img/clp/heroes/TravelShopping.png',
        form: {
          title: 'Travel & Shopping',
          sublead: 'Step 2/2',
          fields: [
            {
              type: 'radio',
              name: 'buy_new_clothes',
              label: 'How often do you buy new clothes?',
              options: [
                { text: 'I shop for mostly used clothes' },
                { text: 'I replace them as needed' },
                { text: 'I like to buy the newest trends each year' },
              ],
            },
            {
              type: 'radio',
              name: 'buy_electronics',
              label: 'How often do you buy electronics?',
              options: [
                { text: 'I buy only a few items and replace as needed' },
                {
                  text: 'I like to buy a range of items, but replace them only as needed',
                },
                {
                  text: 'I  like to buy many gadgets and get the newest versions',
                },
              ],
            },
            {
              type: 'radio',
              name: 'buy_home_furnishings',
              label: 'How often do you buy home furnishings?',
              options: [
                { text: 'I buy mostly used furniture' },
                { text: 'I furnish a room once and rarely change it' },
                { text: 'I change out furnishings in rooms regularly' },
              ],
            },
          ],
          cta: { prev: { text: 'Previous' }, next: { text: 'Next' } },
        },
      },
    },
    {
      name: 'summary',
      title: 'Summary',
      component: {
        templateName: 'clpSummary',
        componentName: 'ClpSummaryComponent',
      },
      html: {
        sections: [
          { title: 'Carbon Overview ', cta: 'Download Full Report' },
          {
            left: {
              title: 'Benchmarking Insights',
              lists: [
                {
                  icon: 'assets/img/clp/icons/User.png',
                  label: 'Your Carbon Footprint',
                  tonne: '3.8',
                },
                {
                  icon: 'assets/img/clp/icons/Goal.png',
                  label: 'Global Target',
                  tonne: '<2',
                },
                {
                  icon: 'assets/img/clp/icons/Geography.png',
                  label: 'Global Average',
                  tonne: '5',
                },
                {
                  icon: 'assets/img/clp/icons/BankEuro.png',
                  label: 'European Average',
                  tonne: '6.8',
                },
              ],
            },
            right: {
              title: 'Carbon Offset',
              desc: 'Your total carbon to offset is',
              longDesc:
                'Balance your carbon footprint by purchasing carbon credits from CLP Carbon Credit Website and receive a certificate for your purchase.',
              cta: 'Request a Quote',
            },
          },
          {
            title: 'Methodology',
            descs: [
              {
                text: 'Learn about what’s at stake and your role in the climate crisis. Measure your own emissions and understand the free tools available to help you reduce them.',
              },
            ],
            cta: 'Download',
          },
          {
            title: 'What to do to be sustainable?',
            image: 'assets/img/clp/heroes/WindTurbines.png',
            descs: [
              {
                text: 'Learn about what’s at stake and your role in the climate crisis. Measure your own emissions and understand the free tools available to help you reduce them.',
              },
              {
                text: 'Absorb more knowledge about small business climate action',
              },
            ],
            cta: 'Learn More',
          },
        ],
      },
    },
  ],
  //    global: [
  //      {
  //        name: 'individual',
  //        title: 'Individual',
  //        component: { templateName: 'clpIndividual', componentName: 'ClpIndividualComponent' }
  //      },
  //      {
  //       name: 'summary',
  //        title: 'Summary',
  //        component: { templateName: 'clpSummary', componentName: 'ClpSummaryComponent' }
  //      }
  //    ],
  //    footerComponents: []
  // }
};

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
