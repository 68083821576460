import { Injectable } from '@angular/core';

import { environment } from './environment';

@Injectable()
export class AppConfiguration {
  private environment: any = environment; 
  
  constructor() {}

  getEnvironment(): any {
    return this.environment
  }
}
