import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clp-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class ClpFormComponent implements OnInit {

  @Input() html: any;

  @Input()
  showBack ?= true;

  @Input()
  enableNext = true;

  @Output()
  forward: EventEmitter<null>;

  @Output()
  back: EventEmitter<null>;

  @Output()
  start: EventEmitter<null>;

  constructor() {
    this.forward = new EventEmitter<null>();
    this.back = new EventEmitter<null>();
    this.start = new EventEmitter<null>();
  }

  ngOnInit(): void {}
  
  goForward() {
    this.forward.emit();
  }

  goBack() {
    this.back.emit();
  }

  goStart() {
    this.start.emit();
  }

  isEmpty(value : any){
    return (value == "" || value == null);
  };

}
