import {
  Component,
  OnInit,
  Type
} from '@angular/core';

import { AppConfiguration } from '../../bootstrap/clp/app.configuration';
import { FeatureComponentRegistryService } from '../../features/clp/feature-component-registry/feature-component-registry.service';

@Component({
  selector: 'app-clp',
  templateUrl: './clp.component.html',
  styleUrls: [
    './clp.component.css'
  ]
})
export class ClpComponent implements OnInit {
  id = 'content';

  activeFeature = 0;

  contentComponents: Array<{ ref: any, component: Type<any> }>;

  featureComponents: Array<{ ref: any, component: Type<any> }>; 
  
  constructor(
    private appConfiguration: AppConfiguration,
    private featureComponentRegistryService: FeatureComponentRegistryService) {}

  ngOnInit(): void {
    this.createFeatureComponents()
  }

  private createFeatureComponents() {
    const env = this.appConfiguration.getEnvironment();

    this.featureComponents =
      new Array<{ ref: any, component: Type<any> }>();

    if (env.featureComponents) {
      env.featureComponents.forEach(featureComponent => {
        this.featureComponents.push({
          ref: featureComponent,
          component: this.featureComponentRegistryService.getComponent(featureComponent.component.componentName)
        });
      });
    }
  }
}
