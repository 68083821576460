import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClpTopNavigationComponent } from './top-navigation.component';

@NgModule({
  declarations: [ClpTopNavigationComponent],
  imports: [
    CommonModule,
  ],
  providers: [],
  exports: [
    ClpTopNavigationComponent
  ],
})
export class ClpTopNavigationModule { }
