import { Component, Injector, OnInit } from '@angular/core';
import { FeatureComponent } from '../../calculators/feature-component-registry/feature-component.interface';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { AppConfiguration } from '../../../bootstrap/clp/app.configuration';

@Component({
  selector: 'app-clp-individual',
  templateUrl: './clp-individual.component.html',
  styleUrls: ['../clp-calculator.component.css']
})

export class ClpIndividualComponent implements OnInit, FeatureComponent {

  data: any

  appConfiguration: AppConfiguration;
   
  html: any = {};
  
  constructor(private injector: Injector) {
     this.appConfiguration = this.injector.get(AppConfiguration);
   }

  ngOnInit(): void {
    this.html = this.appConfiguration.getEnvironment().featureComponents.find(x => x.name == "individual").html;
  }

  /*
  ngAfterViewInit(): void {
    console.log('this', this.constructor.name);
  }
  */

  nextFeature() {
    this.injector.get(NavigationService).slideActiveTabForward();
  }

  previousFeature() {
    this.injector.get(NavigationService).slideActiveTabBack();
  }

}
