<div class="container-fluid clp-container">
  <div class="row">
      <div class="container d-flex align-items-top mx-auto clp-container-home-energy px-0">
          <div class="row align-middle w-100 px-0 m-0">
              <div class="d-none d-md-block col-md-6 clp-bg-blue clp-bg-blue-bg p-5" [ngStyle]="{'background-image': 'url(' +  html.image   + ')'}">
                  <app-clp-top-navigation (start)="start()" [cleanFeatureComponents]="cleanFeatureComponents" [activeComponentGroup]="activeComponentGroup"></app-clp-top-navigation>
                  <div class="d-flex align-items-center">
                      <span class="clp-seqn pr-4">{{ html.seqn }}</span><span class="clp-title">{{ html.title }}</span>
                  </div>
              </div>
              <div class="col-md-6 clp-bg-light-blue p-5">
                  <app-clp-form  
                        [showBack]="true" 
                        (forward)="nextFeature()" 
                        (back)="previousFeature()" 
                        [html]="html">
                  </app-clp-form>
              </div>
          </div>
      </div>
  </div>
</div>