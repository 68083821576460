import { ComponentFactoryResolver, NgModule, ComponentFactory } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { ClpComponent } from '../../clients/clp/clp.component';
import { ClpCalculatorModule } from '../../features/clp/clp-calculator.module';
import { TabCarouselModule } from '../../shared/components/tabbed-container/tab-carousel/tab-carousel.module';

@NgModule({
  declarations: [
    ClpComponent,
  ],
  imports: [
    ClpCalculatorModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    TabCarouselModule,
  ],
  providers: [],
  entryComponents: [
    ClpComponent
  ]
})
export class ClpModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  public resolveComponent(): ComponentFactory<ClpComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(ClpComponent);
  }
}
